<template>
	<div class="reservation-action pt-4">
		<div class="box px-4 py-4 mt-1">
			<div class="row justify-content-center">
				<div class="col-lg-4 text-center">
					<template v-if="structureIsValid">
						<h3>{{$t('reservationAction.title_'+this.actionType)}}</h3>
						<spinner v-if="sending"/>
						<template v-else-if="success === true">
							<p>{{$t('reservationAction.description_success_'+this.actionType)}}</p>
							<router-link :to="{name:'admin',params: {lang: $i18n.locale}}"><div class="btn btn-secondary">{{$t('reservationAction.goToDash')}}</div></router-link>
						</template>
						<template v-else>
							<p>{{$t('reservationAction.description_error')}}</p>
							<router-link :to="{name:'admin',params: {lang: $i18n.locale}}"><div class="btn btn-secondary">{{$t('reservationAction.goToDash')}}</div></router-link>
						</template>
					</template>
					<template v-else>
						<h3>{{$t('reservationAction.title_unknown')}}</h3>
						<p>{{$t('reservationAction.description_unknown')}}</p>
						<router-link :to="{name:'admin',params: {lang: $i18n.locale}}"><div class="btn btn-secondary">{{$t('reservationAction.goToDash')}}</div></router-link>
					</template>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	import {mapState} from "vuex";
	export default {
		name: "ReservationAction",
		components: {Spinner},
		data(){
			return {
				sending: false,
				success: false
			}
		},
		computed: {
			...mapState('concrete', {
				apiKey: 'apiKey'
			}),
			actionType(){
				return this.$route.params.action;
			},
			structureIsValid(){
				return this.actionType === 'accept' || this.actionType === 'decline';
			}
		},
		methods: {
			triggerSend()
			{
				if(this.sending === false)
				{
					this.sending = true;
					this.success = false;
					this.$concreteApi.get(this.apiKey,'/booking_segment_reservations/'+this.$route.params.id+'/'+this.$route.params.action+'/'+this.$route.params.token)
					.then(response => {
						console.log(response);
						this.success = true;
					})
					.catch(error => {
						console.log(error);
						this.success = false;
						this.fireGenericError();
					})
					.then(() => {
						this.sending = false;
					},() => {
						this.sending = false;
					})
				}
			}
		},
		mounted()
		{
			this.triggerSend()
		}
	};
</script>

<style lang="scss" scoped>

</style>
